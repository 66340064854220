import React from 'react'
import Button from 'react-bootstrap/Button'
import { ArrowDownSVG } from './ArrowDownSVG'

const styles = {
  container: {
    display: 'block',
    position: 'absolute',
    width: 50,
    bottom: 15,
    left: -25, // width/2
    right: 0,
    margin: '0 auto',
    cursor: 'pointer',
  },
}

export const NextSectionArrow = ({ color, nextSectionId }) =>
  <div style={styles.container}>
    <Button variant={'link'} className={'vertical-move arrow-down'}
            onClick={() => document.getElementById(nextSectionId).scrollIntoView({ behavior: 'smooth' })}>
      <ArrowDownSVG color={color} />
    </Button>
  </div>
