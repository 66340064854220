import React from 'react'

export const ArrowDownSVG = ({ color }) =>
  <svg xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       width={50}
       x="0px" y="0px" viewBox="0 0 59.414 59.414"
       style={{ enableBackground: 'new 0 0 59.414 59.414' }} xmlSpace="preserve">
    <g>
      <polygon style={{ fill: color || '#000' }}
               points="29.707,45.268 0,15.561 1.414,14.146 29.707,42.439 58,14.146 59.414,15.561 	" />
    </g>
  </svg>
