import React from 'react'
import Container from 'react-bootstrap/Container'
import { Title } from '../Reusable'
import './downloadlinks.css'

import AppStore from './app-store.svg'
import GooglePlay from './google-play.svg'

const styles = {
  container: {
    padding: 15,
    marginTop: -70, // header's height
    borderRadius: 10,
    backgroundColor: 'rgba(255,255,255,0.7)',
    textAlign: 'center',
  },
}

const APP_STORE_LINK = 'https://apps.apple.com/us/app/platetext/id1497603372'
const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.platetext.platetext'

export const DownloadLinks = () => {
  return (
    <Container style={styles.container}>
      <Title>Download it now</Title>

      <a href={APP_STORE_LINK} target={'_blank'} className={'download-link'}>
        <img src={AppStore} alt={'Download on the AppStore'} />
      </a>

      <a href={GOOGLE_PLAY_LINK} target={'_blank'} className={'download-link'}>
        <img src={GooglePlay} alt={'Get it on the Play Store'} />
      </a>

    </Container>
  )
}
