import React from 'react'
import PlateTextLogo from './platetext.png'

const styles = {
  title: {
    display: 'inline-block',
    float: 'right',
    color: '#343A40',
    marginTop: 7,
    marginLeft: 5,
    fontSize: 'xx-large',
    fontFamily: 'Gotham Rounded Light, Helvetica Nueue, sans-serif',
  },
  titlePlate: {
    color: '#3DC2F9',
    fontWeight: 'bold',
  },
  titleText: {
    fontWeight: 'light',
  },
  img: {
    height: 60,
  },
}

export const Logo = ({ ...props }) =>
  <>
    <img src={PlateTextLogo} alt={'PlateText'} style={styles.img} {...props} />
    <div style={styles.title}>
      <span style={styles.titlePlate}>PLATE</span>
      <span style={styles.titleText}>TEXT</span>
    </div>
  </>
