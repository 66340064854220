import React, { useState } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PaperPlane from './paper-plane.svg'
import { mailchimpUrl } from '../../../constants/Config'
import { Colors } from '../colors'

const styles = {
  section: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.red.color,
    padding: '50px 0',
  },
  title: {
    color: '#fff',
    fontSize: 40,
    fontWeight: 'bold',
    fontFamily: 'Gotham Rounded Bold, Helvetica Nueue, sans-serif',
  },
  img: {
    display: 'block',
    height: 40,
    marginTop: -20,
  },
  error: {
    color: Colors.dark.backgroundColor,
  },
}


export const MailChimp = () => {
  const [email, setEmail] = useState('')

  const handleEmailChange = event => {
    event.persist()
    setEmail(event.target.value)
  }

  return (
    <div style={styles.section}>
      <Container>
        <Row>

          <Col xs={12} md={6}>
            <div style={styles.title}>
              <img src={PaperPlane} alt={'Paper Plane Letter'} height={60} className={'mr-2'} /> STAY UPDATED
            </div>
          </Col>

          <Col xs={12} md={6}>
            <MailchimpSubscribe
              url={mailchimpUrl}
              render={({ subscribe, status, message }) => (
                <div className={'mt-1'}>
                  <Form onSubmit={formData => {
                    formData.preventDefault()
                    subscribe({ EMAIL: email })
                  }}>
                    <InputGroup size={'lg'}>
                      <Form.Control
                        placeholder={'email@domain.com'}
                        aria-label={'Email'}
                        name={'email'} value={email}
                        onChange={handleEmailChange}
                      />

                      <InputGroup.Append>
                        <Button type={'submit'}
                                variant={status === 'success' ? 'success' : 'dark'}>
                          {status === 'success' ? 'SUCCESS!' : status === 'sending' ? 'SUBSCRIBING...' : 'SUBSCRIBE'}
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form>

                  {status === 'error' && <div style={styles.error} dangerouslySetInnerHTML={{ __html: message }} />}
                </div>
              )}
            />
          </Col>

        </Row>
      </Container>
    </div>
  )
}
