import React, { useState, useEffect } from 'react'
import { get } from 'axios'
import Container from 'react-bootstrap/Container'
import ReactMarkdown from 'react-markdown'
import { Section } from '../sections/Reusable'
import PrivacyPolicyMD from './privacy-policy.md'

export const PrivacyPolicy = () => {
  const [text, setText] = useState('')

  useEffect(() => {
    get(PrivacyPolicyMD)
      .then(({ data }) => setText(data))
  })

  return (
    <Section>
      <Container>
        <ReactMarkdown source={text} />
      </Container>
    </Section>
  )
}
