import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { NextSectionArrow } from '../../next-section-arrow/NextSectionArrow'
import { DownloadLinks } from './../download-links/DownloadLinks'
import { Section } from '../Reusable'
import { Colors } from '../colors'
import Pattern from './pattern-lightblue.png'
import Phone from './phone.png'

const styles = {
  title: {
    color: '#3DC2F9',
    fontSize: 50,
    fontWeight: 'bold',
    fontFamily: 'Gotham Rounded Medium, Helvetica Nueue, sans-serif',
  },
  section: {
    background: `url(${Pattern})`,
    backgroundColor: Colors.blue.backgroundColor,
    backgroundRepeat: 'repeat',
    backgroundSize: 500,
  },
  phone: {
    display: 'block',
    height: 300,
    margin: '50px auto',
    transform: 'rotate(45deg)',
  },
  leftCol: {
    paddingRight: 30,
  },
  rightCol: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export const Intro = () =>
  <Section id={'section-intro'} style={styles.section}>
    <Container>
      <Row>

        <Col xs={12} sm={6} style={styles.leftCol} className={'intro-left-col'}>
          <h1 style={styles.title}>Text any fellow driver through their License Plate</h1>
          <img src={Phone} alt={'Phone'} style={styles.phone} />
        </Col>

        <Col xs={12} sm={6} style={styles.rightCol}>
          <DownloadLinks />
          {/*<IntroMailChimp />*/}
          {/*<MessageForm />*/}
        </Col>

      </Row>
    </Container>

    <NextSectionArrow color={Colors.blue.color} nextSectionId={'section-flashers'} />
  </Section>
