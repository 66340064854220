import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './index.css'

// http://patorjk.com/software/taag/#p=moreopts&f=Graffiti&t=PLATETEXT
console.log(`
Welcome to

 ____ ____ ____ ____ ____ ____ ____ ____ ____ 
||P |||L |||A |||T |||E |||T |||E |||X |||T ||
||__|||__|||__|||__|||__|||__|||__|||__|||__||
|/__\\|/__\\|/__\\|/__\\|/__\\|/__\\|/__\\|/__\\|/__\\|

`)

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register()
