import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FacebookLogo from './facebook.svg'
import InstagramLogo from './instagram.svg'

const styles = {
  container: {
    backgroundColor: '#1e272c',
    padding: 35,
    color: 'gray',
  },
  socialIcons: {
    height: 30,
    marginRight: 15,
  },
  secondCol: {
    textAlign: 'center',
  },
  aTag: {
    color: 'gray',
    margin: 5,
  },
  thirdCol: {
    textAlign: 'right',
  },
}

export const Footer = () =>
  <Container style={styles.container} fluid>
    <Row>

      <Col xs={{ order: 1, span: 6 }} sm={{ order: 1, span: 4 }}>
        ©PlateText 2020
      </Col>

      <Col style={styles.secondCol} xs={{ order: 3, span: 12 }} sm={{ order: 2, span: 4 }} className={'footer-links'}>
        <a href={'/terms-of-use'} style={styles.aTag}>Terms of Use</a>
        |
        <a href={'/privacy-policy'} style={styles.aTag}>Privacy Policy</a>
      </Col>

      <Col style={styles.thirdCol} xs={{ order: 2, span: 6 }} sm={{ order: 3, span: 4 }}>
        <a href={'https://facebook.com/platetext'} target={'_blank'}>
          <img src={FacebookLogo} alt={'Facebook'} style={styles.socialIcons} />
        </a>

        <a href={'https://instagram.com/platetext'} target={'_blank'}>
          <img src={InstagramLogo} alt={'Instagram'} style={styles.socialIcons} />
        </a>
      </Col>

    </Row>
  </Container>
