import React, { useEffect } from 'react'
import { Section } from '../sections/Reusable'
import { Plate } from '../plate/Plate'
import Colors from '../../constants/Colors'

const styles = {
  section: {
    flexDirection: 'column',
  },
  a: {
    color: Colors.primary,
    marginTop: 50,
  }
}

export const NotFound = () => {

  useEffect(() => {
    if (!window.location.pathname.endsWith('404')) {
      window.location = '/404'
    }
  }, [])

  return (
    <Section style={styles.section}>
      <div>
        {/*eslint-disable-next-line*/}
        <Plate plate={'🙅‍♂️‍ PAGE NOT FOUND 🙅‍♀️'} />
      </div>

      {/*eslint-disable-next-line*/}
      <a href={'/'} style={styles.a}>
        🔙️Let's head back 🏠
      </a>
    </Section>
  )
}
