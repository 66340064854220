import React from 'react'
import Lottie from 'react-lottie'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { NextSectionArrow } from '../../next-section-arrow/NextSectionArrow'
import { Title, Subtitle, Section } from '../Reusable'
import { Plate } from '../../plate/Plate'
import { Colors } from '../colors'

import animationData from './animation.json'

const styles = {
  section: {
    backgroundColor: Colors.dark.backgroundColor,
  },
}

export const SectionFlashers = () =>
  <Section id={'section-flashers'} style={styles.section}>
    <Container>
      <Row>

        <Col xs={{ order: 2, span: 12 }} sm={{ order: 1, span: 6 }}>
          <div className={'lottie'}>
            <Lottie options={{ animationData }} isClickToPauseDisabled={true} />
          </div>
        </Col>

        <Col xs={{ order: 1, span: 12 }} sm={{ order: 2, span: 6 }}>
          <Title color={Colors.yellow.color}>
            Howdy <Plate plate={'8UZZL1GHT'} />
            <br /> You forgot your flashers on! 💡
          </Title>

          <Subtitle style={{ color: Colors.dark.color }}>
            Searching for a compatriot to boost your car is never a fun thing in the morning, or evening.
          </Subtitle>
        </Col>

      </Row>
    </Container>

    <NextSectionArrow color={Colors.yellow.color} nextSectionId={'section-convertible'} />
  </Section>
