import React from 'react'
import Lottie from 'react-lottie'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Plate } from '../../plate/Plate'
import { Title, Subtitle, Section } from '../Reusable'
import { Colors } from '../colors'

import animationData from './animation.json'

const styles = {
  section: {
    backgroundColor: Colors.red.backgroundColor,
  },
}

export const SectionTowing = () =>
  <Section id={'section-towing'} style={styles.section}>
    <Container>
      <Row>

        <Col xs={{ order: 2, span: 12 }} sm={{ order: 1, span: 6 }}>
          <div className={'lottie'}>
            <Lottie options={{ animationData }} isClickToPauseDisabled={true} />
          </div>
        </Col>

        <Col xs={{ order: 1, span: 12 }} sm={{ order: 2, span: 6 }}>
          <Title color={Colors.red.color}>
            Hey <Plate plate={'McQu33n'} />
            <br /> You're being towed! 🚨
          </Title>

          <Subtitle>
            Because tow trucks never miss a chance to get you, whether for snow removal,
            or simply parked in a reserved zone.
          </Subtitle>
        </Col>

      </Row>
    </Container>
  </Section>
