import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import { useIsAtTop } from '../useIsAtTop'
import { Logo } from '../logo/Logo'

const styles = {
  navbar: {
    position: 'fixed',
    width: '100%',
    zIndex: 2,
  },
}

export const Header = () =>
  <Navbar style={styles.navbar} className={useIsAtTop() ? 'no-scroll' : 'scroll'}>
    <Navbar.Brand href={'/'}>
      <Logo />
    </Navbar.Brand>
  </Navbar>
