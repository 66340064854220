import React from 'react'
import Button from 'react-bootstrap/Button'
import { Colors } from './colors'

const styles = {
  title: {
    color: '#3DC2F9',
    fontSize: 'xx-large',
    fontWeight: 'bold',
    fontFamily: 'Gotham Rounded Light, Helvetica Nueue, sans-serif',
  },
  subtitle: {
    color: '#444',
    fontSize: 'x-large',
    marginTop: 15,
    fontFamily: 'Gotham Rounded Light',
  },
  section: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    padding: '150px 15px',
  },
  button: {
    color: 'white',
    backgroundColor: Colors.blue.color,
    borderColor: Colors.blue.color,
  },
}

export const Title = ({ color, children, ...props }) =>
  <div {...props} style={{ ...styles.title, color }}>
    {children}
  </div>

export const Subtitle = ({ children, style, ...props }) =>
  <div style={{ ...styles.subtitle, ...style }} {...props}>
    {children}
  </div>

export const Section = ({ id, children, style, ...props }) =>
  <div id={id} className={'section'} style={{ ...styles.section, ...style }} {...props}>
    {children}
  </div>

export const SubmitButton = ({ children, style, ...props }) =>
  <Button type={'submit'} size={'lg'} variant={'warning'} style={{ ...styles.button, ...style }} {...props}>
    {children}
  </Button>
