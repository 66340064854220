export const Colors = {
  blue: {
    color: '#3DC2F9',
    backgroundColor: '#EAF7FB',
  },
  yellow: {
    color: '#F5BB42',
    backgroundColor: '#FEF6E6',
  },
  red: {
    color: '#EE8575',
    backgroundColor: '#FBEEEA',
  },
  dark: {
    color: '#FFFFFF',
    backgroundColor: '#1E272C',
  },
}
