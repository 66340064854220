import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CountUp from 'react-countup'
import { Colors } from '../colors'
import Cars from './cars.svg'

const styles = {
  section: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: Colors.dark.backgroundColor,
    backgroundColor: Colors.blue.color,
    padding: '50px 0',
    fontFamily: 'Gotham Rounded Medium, Helvetica Nueue, sans-serif',
  },
  count: {
    color: '#fff',
    fontSize: 60,
    fontWeight: 'bold',
    fontFamily: 'Gotham Rounded Medium, Helvetica Nueue, sans-serif',
  },
  text: {
    fontSize: 40,
    marginTop: 50,
  },
  textDrivers: {
    fontWeight: 'bold',
    color: '#fff',
  },
}

const INITIAL_NUMBER_CARS = 150000000
const TOTAL_NUMBER_CARS = 250000000

export const Statistics = () => {
  const [endCounter, setEndCounter] = useState(INITIAL_NUMBER_CARS)

  const onVisibilityChange = isVisible => {
    setEndCounter(isVisible ? TOTAL_NUMBER_CARS : INITIAL_NUMBER_CARS)
  }

  return (
    <div style={styles.section}>
      <Container>
        <Row>

          <Col xs={12} lg={6}>
            <img src={Cars} alt={'Cars'} height={120} />

            <br />

            <VisibilitySensor onChange={onVisibilityChange}>
              <CountUp separator={','} suffix={'+'} duration={1}
                       start={INITIAL_NUMBER_CARS} end={endCounter}
                       style={styles.count} className={'number-of-cars'} />
            </VisibilitySensor>

          </Col>

          <Col xs={12} lg={6}>
            <div style={styles.text}>Number of <span style={styles.textDrivers}>drivers</span>
              <br />
              in Canada and USA
            </div>
          </Col>

        </Row>
      </Container>
    </div>
  )
}
