import React, { Component } from 'react'
import { Router, Route, Switch } from 'react-router'
import { createBrowserHistory } from 'history'
import { Header } from './modules/header/Header'
import { Home } from './modules/sections/Home'
import { Footer } from './modules/sections/footer/Footer'
import { PrivacyPolicy } from './modules/privacy-policy/PrivacyPolicy'
import { TermsOfUse } from './modules/terms-of-use/TermsOfUse'
import { NotFound } from './modules/404/NotFound'
import 'bootstrap/dist/css/bootstrap.min.css'

class App extends Component {
  render() {
    return (
      <>
        <Header />

        <Router history={createBrowserHistory()}>
          <Switch>
            <Route component={Home} path={'/'} exact />
            <Route component={TermsOfUse} path={'/terms-of-use'} />
            <Route component={PrivacyPolicy} path={'/privacy-policy'} />
            <Route component={NotFound} />
          </Switch>
        </Router>

        <Footer />
      </>
    )
  }
}

export default App
