import React from 'react'
import Colors from '../../constants/Colors'
import Hole from './hole.png'

const styles = {
  container: {
    color: Colors.primary,
    backgroundImage: `url(${Hole}), url(${Hole}), url(${Hole}), url(${Hole})`,
    backgroundPosition: '15% 3px, 85% 3px, 15% 40px, 85% 40px',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fff',
    border: `4px solid ${Colors.primary}`,
    borderRadius: 10,
    padding: '10px 15px 6px 17px',
    textShadow: '-1px 1px lightgray',
    letterSpacing: 2,
    fontSize: 'xx-large',
    fontFamily: 'HeavyEquipment',
    whiteSpace: 'nowrap',
  },
}

export const Plate = ({ plate }) => <span style={styles.container}>{plate}</span>
