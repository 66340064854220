import React from 'react'
import Lottie from 'react-lottie'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Plate } from '../../plate/Plate'
import { NextSectionArrow } from '../../next-section-arrow/NextSectionArrow'
import { Title, Subtitle, Section } from '../Reusable'
import { Colors } from '../colors'
import Rain from './rain.gif'

import animationData from './animation.json'

const styles = {
  section: {
    backgroundImage: `url(${Rain})`,
    backgroundColor: Colors.blue.backgroundColor,
    backgroundRepeat: 'repeat',
    backgroundSize: 0,
  },
}

export const SectionConvertible = () =>
  <Section id={'section-convertible'} style={styles.section}>
    <Container>
      <Row>

        <Col xs={{ order: 1, span: 12 }} sm={{ order: 1, span: 6 }}>
          <Title color={Colors.blue.color}>
            Bond, <Plate plate={'JAM3S 80ND'} />
            <br /> It's raining in your convertible 🌧️
          </Title>

          <Subtitle>
            Poor fella was too busy saving our planet, and forgot about his car's roof wide open in the rain.
          </Subtitle>
        </Col>

        <Col xs={{ order: 2, span: 12 }} sm={{ order: 2, span: 6 }}>
          <div className={'lottie'}>
            <Lottie options={{ animationData }} isClickToPauseDisabled={true} />
          </div>
        </Col>

      </Row>
    </Container>

    <NextSectionArrow color={Colors.blue.color} nextSectionId={'section-towing'} />
  </Section>
