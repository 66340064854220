import { useState, useEffect } from 'react'

const top = () => (document.scrollingElement || document.documentElement).scrollTop
const getIsAtTop = () => top() <= 50 // 50px top offset

export const useIsAtTop = () => {
  const [isAtTop, setIsAtTop] = useState(getIsAtTop())

  const handleScroll = () => {
    setIsAtTop(getIsAtTop())  // 50px top offset
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return isAtTop
}
